import './App.css';

function App() {
  return (
    <div className="App">
      <h2 className="header">hello there<br></br>sarah is<br></br>currently</h2>
      <div className="loading">
        <p>loading<span>...</span></p>
      </div>
    </div>
  );
}

export default App;
